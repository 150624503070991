import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import { Container, Row, Column, Inner } from '../components/ui/layout/Grid'
import {
  ContentBox,
  ContentBoxIcon,
  SocialMediaBox
} from '../components/ui/layout/ContentBox'
import Image from '../components/ui/layout/Image'
import LinkOut from '../components/ui/forms/LinkOut'
import Hero from '../components/Hero'
import SEO from '../components/seo'
import Button from '../components/ui/forms/Buttons'
import Newsletter from '../components/Newsletter'
import theme from '../theme'

// Illustrations
import value from '../images/assets/value.svg'

// Icons
import quoteIcon from '../images/assets/icons/quote-icon.svg'

const Home = () => {
  const {
    site: {
      siteMetadata: { socialMedia }
    }
  } = useStaticQuery(socialMediaQuery)
  return (
    <>
      <SEO title="Home" />
      <Hero />
      <Container>
        <Row mb="5em">
          <Column>
            <ContentBox
              lift
              textAlign="center"
              bg={theme.color.primaryColor}
              style={{
                overflow: 'hidden'
              }}
            >
              <h2>What is Investabit?</h2>
              <p style={{ marginBottom: '1.2em' }}>
                With the tools built by and with Investabit you will have the ability to algorithmically execute trading strategies, build complex models and run simulations. It is a full suite for anyone wishing to enter the cryptocurrency market, without having to deal with all the nuances and frustrations in the industry.
              </p>
              <Button
                appearance="secondary"
                to="/about"
              >
                What Makes Investabit Tick?
              </Button>
            </ContentBox>
          </Column>
        </Row>
        <Row
          mb="5em"
          align="center"
        >
          <Column>
            <ContentBox
              bg="#ffed95"
              bgPattern={true}
            >
              <ContentBoxIcon
                icon={quoteIcon}
                outside
              />
              <p>
                Investabit is a source for <strong>freedom</strong> to explore and build ideas, in order to create a seamless experience for interfacing with the emerging crypto asset class.
              </p>
            </ContentBox>
          </Column>
          <Column>
            <Inner>
              <Image
                src={value}
                alt="Value"
                padding="2em"
              />
            </Inner>
          </Column>
        </Row>
        <Newsletter />
        <Row>
          <Column
            dir="row"
            justify="center"
          >
            <h2>Stay in Touch</h2>
          </Column>
        </Row>

        <Row grid={1 / 3}>
          {socialMedia.map((service, idx) => (
            <Column key={idx}>
              <Inner>
                <SocialMediaBox bg={service.color}>
                  <LinkOut to={`https://${ service.url }`}>
                    <ContentBoxIcon
                      icon={service.site}
                      outside
                    />
                    {service.url}
                  </LinkOut>
                </SocialMediaBox>
              </Inner>
            </Column>
          ))}
        </Row>
      </Container>
    </>
  )
}

export const socialMediaQuery = graphql`
  query SocialMedia {
    site {
      siteMetadata {
        socialMedia {
          site
          color
          url
        }
      }
    }
  }
`

export default Home
