import PropTypes from 'prop-types'
import styled from 'styled-components'
import { lighten } from 'polished'

import { devices } from '../../../theme'

const Input = styled.input`
  border: 1px solid ${ props => props.theme.color.grey[2] };
  font-size: 2rem;
  padding: 0.8em 1em;
  transition: all 0.25s ${ props => props.theme.animation };
  outline: 0;
  box-shadow: ${ props => props.hasError && `inset 0 0 0 2px ${props.theme.color.error }` };

  :hover {
    border-color: ${ props => props.theme.color.primaryColor };
  }

  :focus {
    border-color: ${ props => props.theme.color.primaryColor };
    box-shadow: 0 0 0.5px 4px
      ${ props => lighten(0.12, props.theme.color.primary[1]) };
  }

  @media (${ devices.tablet }) {
    font-size: 1.6rem;
  }
`

const Label = styled.label`
  font-weight: 700;
  font-size: 1.6rem;
  margin-bottom: 0.5em;
`

const FormGroup = styled.div`
  position: relative;
  padding: 1.2em 0;
  display: flex;
  flex-direction: ${ props => props.flexDirection || 'column' };

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`

const Message = styled.span`
  color: ${ props => props.theme.color.error };
  position: absolute;
  bottom: -15px;
`

FormGroup.propTypes = {
  flexDirection: PropTypes.oneOf(['row', 'column'])
}

export { Input, Label, FormGroup, Message }
