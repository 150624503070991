import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { darken } from 'polished'

import { devices } from '../../../theme'
import logoIcon from '../../../images/logo-icon.svg'

// Social Media icons
import twitter from '../../../images/assets/social_media/twitter.svg'
import facebook from '../../../images/assets/social_media/facebook.svg'
import github from '../../../images/assets/social_media/github.svg'
import slack from '../../../images/assets/social_media/slack.svg'
import medium from '../../../images/assets/social_media/medium.svg'
import telegram from '../../../images/assets/social_media/telegram.svg'

import pattern from '../../../images/assets/content_bg.svg'

const SOCIAL_ICONS = {
  twitter: twitter,
  facebook: facebook,
  github: github,
  slack: slack,
  medium: medium,
  telegram: telegram
}

const ContentBox = styled.div`
  background-color: #fff;
  background-image: ${ props => (props.bgPattern ? `url(${ pattern })` : null) };
  background-size: cover;
  color: ${ props => props.color };
  padding: 3em;
  width: 100%;
  text-align: ${ props => props.textAlign };
  position: relative;
  align-self: center;
  z-index: 100;
  border: 1px solid ${ props => props.theme.color.grey[1] };
  box-shadow: 0 5px 20px ${ props => props.theme.color.grey[0] };
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: ${ props => props.bg };
    z-index: -1;
  }

  h2 {
    margin: 0;
  }

  ${ props =>
    props.lift &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -25%;
        background-image: url(${ logoIcon });
        background-repeat: no-repeat;
        background-size: cover;
        background-size: 50%;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0.15;
      }
    ` }

  p {
    font-size: 2.2rem;
    margin: 0 auto;
  }

  @media (${ devices.tabletWide }) {
    p {
      font-size: 1.8rem;
    }
  }

  @media (${ devices.mobile }) {
    padding: 1.5em;
  }
`

const ContentBoxIcon = styled.div`
  background-image: ${ props => `url(${ SOCIAL_ICONS[props.icon] || props.icon })` };
  background-repeat: no-repeat;
  background-size: 64px 64px;
  width: 64px;
  height: 64px;
  margin: 0 auto;

  ${ ({ outside }) =>
    outside &&
    css`
      position: absolute;
      top: -32px;
      left: 50%;
      transform: translateX(-50%);
    ` }
`

const SocialMediaBox = styled(ContentBox)`
  background-color: #fff;
  border: 1px solid ${ props => props.theme.color.grey[1] };
  padding: 0;
  overflow: hidden;
  transition: border-color 0.25s ${ props => props.theme.animation };

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${ props => props.bg };
    width: 100%;
    height: 100%;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.25s ${ props => props.theme.animation };
    z-index: -1;
  }

  :hover {
    border-color: ${ props => props.bg };

    &::before {
      transform: scaleX(1);
    }

    a {
      color: #fff;
    }
  }

  a {
    padding: 3em 0;
    display: inline-block;
    width: 100%;
    text-align: center;
    color: ${ props => darken(0.2, props.bg) };
    font-size: 2rem;
    transition: color 0.25s ${ props => props.theme.animation };
    z-index: 500;
  }

  ${ ContentBoxIcon } {
    background-size: 128px;
    background-position: center;
    left: auto;
    top: auto;
    right: -64px;
    bottom: -42px;
    width: 128px;
    height: 128px;
    opacity: 0.3;
    z-index: -2;
  }

  @media (${ devices.tabletWide }) {
    a {
      word-wrap: break-word;
    }
  }
`

ContentBox.propTypes = {
  bg: PropTypes.string,
  border: PropTypes.string,
  bgPattern: PropTypes.bool,
  color: PropTypes.string,
  textAlign: PropTypes.oneOf(['left', 'center', 'right'])
}

ContentBoxIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  outside: PropTypes.bool
}

export { ContentBox, ContentBoxIcon, SocialMediaBox }
