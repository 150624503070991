import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { Container, Row, Column } from '../ui/layout/Grid'
import Button from '../ui/forms/Buttons'
import { TopTitle } from './PageIntro'
import factory from '../../images/assets/factory.svg'
import { devices } from '../../theme'

const HeroContainer = styled(Row)`
  padding: 6em 0;

  h1 {
    font-size: 6.3rem;
    margin: 0;
    line-height: 1.15;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -60px;
      left: -60px;
      background-image: radial-gradient(
        ${ props => props.theme.color.grey[1] } 25%,
        transparent 25%
      );
      background-size: 20px 20px;
      width: 240px;
      height: 240px;
      z-index: -1;
    }

    @media (${ devices.tablet }) {
      &::before {
        display: none;
      }
    }
  }

  p {
    font-size: 2.6rem;
    color: ${ props => props.theme.color.grey[4] };
  }

  @media (${ devices.tabletWide }) {
    h1 {
      font-size: 4.8rem;
    }

    p {
      font-size: 2.1rem;
    }
  }
`

const HeroImage = styled.div`
  width: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(${ factory });
    background-repeat: no-repeat;
    width: 900px;
    height: 600px;
  }

  @media (max-width: 1800px) {
    &::before {
      width: 640px;
      height: 460px;
      margin-left: 2em;
    }
  }

  @media (${ devices.desktop }) {
    position: relative;
    height: 100%;

    &::before {
      width: 100%;
      height: 100%;
      margin-left: 0;
    }
  }
`

const Hero = () => {
  const {
    site: {
      siteMetadata: { tagline, description }
    }
  } = useStaticQuery(descriptionQuery)
  return (
    <Container>
      <HeroContainer>
        <Column dir="column">
          <TopTitle>Industry Leaders</TopTitle>
          <h1>{tagline}</h1>
          <p>{description}</p>
          <Row>
            <Button
              as="a"
              appearance="primary"
              href="#subscribe"
            >
              Subscribe Now
            </Button>
            <Button
              appearance="secondary"
              to="/about"
            >
              About Investabit
            </Button>
          </Row>
        </Column>
        <Column>
          <HeroImage />
        </Column>
      </HeroContainer>
    </Container>
  )
}

export const descriptionQuery = graphql`
  query heroQuery {
    site {
      siteMetadata {
        title
        tagline
        description
      }
    }
  }
`

export default Hero
