import React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'
import { Row, Column } from '../ui/layout/Grid'
import { Input, FormGroup, Label } from '../ui/forms/Input'
import Button from '../ui/forms/Buttons'
import Image from '../ui/layout/Image'
import { devices } from '../../theme'

import newsletter from '../../images/assets/newsletter.svg'

const postAction =
  'https://investabit.us17.list-manage.com/subscribe/post?u=7ecbf131b8fdac8776e2d05ed&amp;id=2d7423f389'

const NewsletterContainer = styled(Row)`
  border: 1px solid ${ props => props.theme.color.grey[1] };

  ${ Column }:nth-child(2) {
    background-color: ${ props => lighten(0.018, props.theme.color.grey[0]) };
  }
`

const Form = styled.div`
  ${ Input } {
    padding: 0.58em 1em;
    flex-grow: 1;
  }

  ${ Button } {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  h2,
  p {
    margin: 0;
  }

  p {
    color: ${ props => props.theme.color.grey[4] };
  }

  span {
    font-size: 1.5rem;
    color: ${ props => props.theme.color.primaryColor };
  }

  @media (${ devices.desktop }) {
    padding: 1.5em;
  }

  @media (${ devices.tablet }) {
    padding: 2em;
    text-align: center;
  }
`

const Newsletter = () => (
  <NewsletterContainer
    mt="5em"
    mb="5em"
    id="subscribe"
  >
    <Column>
      <Image
        src={newsletter}
        alt="Sign up for our newsletter!"
        padding="3.5em"
      />
    </Column>
    <Column
      dir="column"
      justify="center"
      align="center"
    >
      <Form>
        <h2 id="email-form">Sign up for our newsletter!</h2>
        <p>We are not quite ready to launch yet but we'll let you know!</p>
        <form
          action={postAction}
          method="POST"
          target="_blank"
          noValidate
        >
          <input
            name="u"
            value="052b7b6937d935e23d7e03274"
            type="hidden"
          />
          <input
            name="id"
            value="cbb0c4942f"
            type="hidden"
          />

          <FormGroup flexDirection="row">
            <Input
              className="input"
              id="MERGE0"
              type="email"
              name="MERGE0"
              placeholder="Your email here..."
              autoCapitalize="off"
              autoCorrect="off"
              required
              aria-labelledby="email-form"
            />
            <input
              name="mc_signupsource"
              value="hosted"
              type="hidden"
            />
            <Button
              appearance="primary"
              as="button"
            >
              Notify Me
            </Button>
          </FormGroup>
        </form>
        <span>We will not spam you. Pinky promise!</span>
      </Form>
    </Column>
  </NewsletterContainer>
)

export default Newsletter
