import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Column, Inner } from '../ui/layout/Grid'
import Button from '../ui/forms/Buttons'
import styled from 'styled-components'

import Image from '../ui/layout/Image'

const Title = styled.h1`
  margin: 0;
`

const TopTitle = styled.small`
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: ${ props => props.theme.color.primaryColor };
  margin-bottom: 0.3em;
  margin-left: 0.2em;
`

const Subtitle = styled.p`
  font-size: 2.2rem;
  margin: 0;
  color: ${ props => props.theme.color.grey[4] };
`

const Heading = styled(Column)`
  max-width: 75rem;
  margin: 0 auto;
`

const PageIntro = ({ title, subtitle, topTitle, cta, image, ...rest }) => (
  <Container {...rest}>
    <Row
      mb="5em"
      mt="5em"
      align="center"
    >
      <Heading dir="column">
        {topTitle && <TopTitle>{topTitle}</TopTitle>}
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        {cta && (
          <Row css={cta.style}>
            <Button
              appearance={cta.appearance || 'secondary'}
              to={cta.to}
            >
              {cta.label}
            </Button>
          </Row>
        )}
      </Heading>
      {image && (
        <Column>
          <Inner>
            <Image {...image} />
          </Inner>
        </Column>
      )}
    </Row>
  </Container>
)

PageIntro.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    to: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    appearance: PropTypes.oneOf(['primary', 'secondary', 'link']),
    style: PropTypes.string
  }),
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    padding: PropTypes.string,
    style: PropTypes.string
  })
}

export { PageIntro as default, TopTitle }
