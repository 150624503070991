import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const ImageContainer = styled.div`
  padding: ${ props => props.padding };
`

const Image = ({ src, alt, css, padding }) => (
  <ImageContainer
    css={css}
    padding={padding}
  >
    <img
      src={src}
      alt={alt}
    />
  </ImageContainer>
)

ImageContainer.propTypes = {
  padding: PropTypes.string
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
}

export default Image
